<template>
	<v-card tile>
		<v-toolbar
          dark
		  dense
		  elevation="0"
          color="primary"
        >
          <v-toolbar-title>添加团队到今日项目</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
            @click="$emit('on-close')"
            >
              关闭
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
		<v-divider></v-divider>
		<v-card-text class="px-0 pt-0 pb-0">
			<!--- 工人团队搜索框 --->
			<div class="pa-3">
				<v-text-field
					label="搜索工人团队"
					v-model="search"
					outlined
					dense
					hide-details
					clearable
					clear-icon="mdi-close"
					prepend-inner-icon="mdi-magnify"
					@click:clear="clearTeamSearch"
					@input="onSearchTeam"
					placeholder="输入工人团队名称"
					class="mx-1 my-2"
				></v-text-field>
				<v-spacer></v-spacer>
			</div>
			<v-divider></v-divider>
			<!--- loading overlay ---->
			<template v-if="loading">
				<div class="d-flex justify-center align-center flex-column mt-8 py-8">
					<v-progress-circular
					:size="140"
					:width="2"
					color="primary"
					indeterminate
					></v-progress-circular>
					<div class="body-1 mt-4">载入数据中</div>
				</div>
			</template>
			<template v-else>

				<template v-if="search && search.length">
					<template v-if="searchResult.length">
						<v-data-table
							:headers="headers"
							:items="searchResult"
							:single-expand="singleExpand"
							:expanded.sync="expanded"
							item-key="name"
							show-expand
							:footer-props="{
								'items-per-page-text': '每页显示',
								//'items-per-page-options': items
							}"
							@item-expanded="onTeamExpand($event)"
						>
							<template v-slot:item.actions="{item}">
								<v-btn color="success" small elevation="0" @click="addTeamToProjectTimesheet(item)">
									<v-icon small left>mdi-plus</v-icon>
									添加团队
								</v-btn>
							</template>
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length" class="px-0 grey lighten-4">
									<template v-if="item.workers && item.workers.length">
										<v-list dense subheader class="pb-0" >
											<v-subheader class="text-center grey lighten-4">{{ item.name }}工人</v-subheader>
											<v-divider></v-divider>
											<template v-for="worker in item.workers">
												<v-list-item class="grey lighten-4">
													<v-list-item-content>
														<v-list-item-title>{{ worker.name }}</v-list-item-title>
														<v-list-item-subtitle>{{ worker.email }}</v-list-item-subtitle>
														<v-list-item-subtitle>{{ worker.role }}</v-list-item-subtitle>
													</v-list-item-content>
													<v-list-item-action>
														<v-btn color="success" small elevation="0" @click="addWorkerToProjectTimesheet(worker)">
															<v-icon small left>mdi-plus</v-icon>
															添加
														</v-btn>
													</v-list-item-action>
												</v-list-item>
												<v-divider></v-divider>
											</template>
										</v-list>
									</template>
									<template v-else>
										<template v-if="workerLoader">
											<div class="d-flex justify-center align-center flex-column mt-4 py-4">
												<v-progress-circular
												:size="40"
												:width="2"
												color="primary"
												indeterminate
												></v-progress-circular>
												<div class="body-2 mt-2">获取团队工人数据中...</div>
											</div>
										</template>
										<template v-else>
											<div class="pa-6 text-center body-2">
												没有工人在<strong>{{ item.name }}</strong>的工作团队中
											</div>
										</template>
									</template>
								</td>
							</template>
						</v-data-table>					
					</template>
					<template v-else>
						<div class="body-2 text-center py-8">无法找到符合关键字<strong>{{ search }}</strong>的结果</div>
					</template>
				</template>
				<template v-else>
					<template v-if="teams.length">
						<v-data-table
							:headers="headers"
							:items="teams"
							:single-expand="singleExpand"
							:expanded.sync="expanded"
							item-key="name"
							show-expand
							:footer-props="{
								'items-per-page-text': '每页显示',
								//'items-per-page-options': items
							}"
							@item-expanded="onTeamExpand($event)"
						>
							<template v-slot:item.actions="{item}">
								<v-btn color="success" small elevation="0" @click="addTeamToProjectTimesheet(item)">
									<v-icon small left>mdi-plus</v-icon>
									添加团队
								</v-btn>
							</template>
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length" class="px-0 grey lighten-4">
									<template v-if="item.workers && item.workers.length">
										<v-list dense subheader class="pb-0" >
											<v-subheader class="text-center grey lighten-4">{{ item.name }}工人</v-subheader>
											<v-divider></v-divider>
											<template v-for="worker in item.workers">
												<v-list-item class="grey lighten-4">
													<v-list-item-content>
														<v-list-item-title>{{ worker.name }}</v-list-item-title>
														<v-list-item-subtitle>{{ worker.email }}</v-list-item-subtitle>
														<v-list-item-subtitle>{{ worker.role }}</v-list-item-subtitle>
													</v-list-item-content>
													<v-list-item-action>
														<v-btn color="success" small elevation="0" @click="addWorkerToProjectTimesheet(worker, item)">
															<v-icon small left>mdi-plus</v-icon>
															添加
														</v-btn>
													</v-list-item-action>
												</v-list-item>
												<v-divider></v-divider>
											</template>
										</v-list>
									</template>
									<template v-else>
										<template v-if="workerLoader">
											<div class="d-flex justify-center align-center flex-column mt-4 py-4">
												<v-progress-circular
												:size="40"
												:width="2"
												color="primary"
												indeterminate
												></v-progress-circular>
												<div class="body-2 mt-2">获取团队工人数据中...</div>
											</div>
										</template>
										<template v-else>
											<div class="pa-6 text-center body-2">
												没有工人在<strong>{{ item.name }}</strong>的工作团队中
											</div>
										</template>
									</template>
								</td>
							</template>
						</v-data-table>
					</template>
					<template v-else>
						<div class="body-2 text-center py-8">没有工作组在这个项目中，请在项目设置中添加工作团队</div>
					</template>
				</template>
			</template>
		</v-card-text>
	</v-card>
</template>
<script>
import ProjectServices from '../../services/Project';
import WorkTeamService from '../../services/WorkTeam';
import { mapState } from 'vuex';

export default {
	name: 'AddTeamToTimesheetDialog',
	data: ()=>({
		search: '',
		loading: true,
		workerLoader: true,
		teams: [],
		expanded: [],
        singleExpand: false,
		headers: [
			{ text: 'id', value: 'id'},
			{ text: '团队名称', value: 'name'},
			{ text: '工头', value: 'owner.name'},
	        { text: '功能', value: 'actions', sortable: false, width: 200, align: 'end',  }
		],
		searchResult: [],
	}),
	props: {
		projectId: {
			type: String | Number,
			required: true,
		}
	},
	created(){
		this.getProjectAssignedTeam();
	},
	computed: {
		...mapState('user', ['user'],),
	},
	methods: {
		async getProjectAssignedTeam(){
			try {
				let response = await ProjectServices.listWorkTeamsInProject(this.projectId);
				 if(response.data.statusCode === 200){
					response.data.data.forEach(team=>{
						team.workers = []
						this.teams.push(team);
						this.loading = false;
					})
				}
			} catch (error){
				this.popToast(error, 'error');
			}
		},
		addTeamToProjectTimesheet(team) {
			this.$emit('on-team-add', team);
		},
		addWorkerToProjectTimesheet(worker, team){
			//call endpoint to add worker to project timesheet. need pop totast
			const workTeam ={
				'team': team,
				'worker': worker
			}
			this.$emit('on-worker-add', workTeam);
		},
		onTeamExpand(team) {
			if (team && team.item.id) {
				this.workerLoader = true;
				this.getTeamDetail(team.item.id)
			}
		},
		async getTeamDetail(team_id){
			try {
				let response;
				response = await WorkTeamService.getWorkTeam(team_id);
				const team = response.data.data;

				let _team = this.teams.find( item => item.id === team_id); 
				_team.workers = team.workers;
				this.workerLoader = false;
			} catch(error) {;
				this.popToast(error, 'error');
			}
		},
		onSearchTeam(){
			const searchResult = this.teams.filter((team)=>{
				return team.name.includes(this.search);
			})
			this.searchResult = searchResult
		},
		clearTeamSearch(){
			this.search = ''
			this.searchResult = [];
		},
		popToast(message, type){
			const toast = {
				message: message,
				color: type,
			}
			this.$emit('on-pop-toast', toast);
		},

	}
}
</script>
