<!-- 编辑团队或者个人的timesheet 组件 -->
<template>
	<v-card tile>
		<v-toolbar dense dark color="primary" elevation="0">
			<v-app-bar-nav-icon>
				<v-btn icon @click="$emit('on-close')"><v-icon>mdi-close</v-icon></v-btn>
			</v-app-bar-nav-icon>
			<v-toolbar-title>
				编辑工人工作记录
			</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-toolbar>
		<v-divider></v-divider>
		<v-card-text class="px-0 py-4">
			<div class="d-flex flex-column" v-for="worker in workers" :key="worker.id">
				<worker-timesheet-entry-card
					:worker="worker"
					:workTypes.sync="workTypes" />
				<v-divider></v-divider>
			</div>
		</v-card-text>
		<v-footer fixed class="py-2">
			<v-btn color="success" block @click="onSaveTimesheet"> <v-icon left>mdi-content-save</v-icon>保存记录</v-btn>
		</v-footer>
	</v-card>
</template>
 
<script>
import workerTimesheetEntryCard from './workerTimesheetEntryCard.vue'

import ProjectServices from '../../services/Project';
import TimesheetService from '../../services/Timesheet';

export default {
	name: 'timesheetForm',

	data: () => ({
		workTypes: [],
	}),
	props: {
		workers: {
			type: Array,
			required: true
		},
		projectId: {
			type: Number,
			required: true
		},
	},
	components: {
		workerTimesheetEntryCard
	},
	created(){
		this.getWorkTypeList();
	},
	watch: {
	},
	methods: {
		async getWorkTypeList(){
			try {
				let response = await ProjectServices.listAvailableWorkTypes(this.projectId);
				if(response.status === 200){
						response.data.data.forEach(workType=>{
							this.workTypes.push(workType);
						})
				}
			} catch(error){
				console.log(error)
			}
		},
		onSaveTimesheet(){
			this.workers.forEach((worker)=>{
				worker.activities.forEach((activity, i)=>{
					let body ={
						type: "day_labour",
						work_type_id: activity.work_type.id,
						duration: (parseFloat(activity.duration) * 60).toFixed(0),
						notes: activity.notes
					}
					let groupMemberId = worker.id;
					let numberOfRequests = worker.activities.length
					let isBulkRequestCompleted = Number(numberOfRequests) ===  Number(i+1)
					if(activity.id){
						//update 
						console.log('update')
						this.updateTimesheetChange(groupMemberId, activity.id, body, isBulkRequestCompleted)
					} else {
						//add new
						this.postTimesheetChange(groupMemberId, body, activity, isBulkRequestCompleted)
					}
					
				})
			})
		},
		async updateTimesheetChange(groupMemberId, activityId, body, isCompleted){
			try {
				let response = await TimesheetService.editActivity(groupMemberId, activityId, body);
				if(response.status === 200){
					//emit saving success toast
					if(isCompleted){
						activityId = response.data.data.id;
						this.$emit('on-update-success', {	message: `工作记录更新成功！`, type: 'success'})
					}
				}
			} catch(error){
				console.log(error)
			}
		},
		async postTimesheetChange(groupMemberId, body, activity, isCompleted) {
			try {
				let response = await TimesheetService.createActivity(groupMemberId, body);
				if(response.status === 200){
					//emit saving success toast
					if(isCompleted){
						activity.id = response.data.data.id;
						this.$emit('on-update-success', {	message: `工作记录更新成功！`, type: 'success'})
					}
				}
			} catch(error){
				console.log(error)
			}
		}
	}
}
 </script>
 
 <style>
 
 </style>