<template>
	<v-card outlined elevation="0" width="281px">

		<div class="d-flex align-top flex-start">
			<div :class="canselect ? 'mr-2' : 'mr-4'">
				<v-checkbox v-if="canselect" v-model="workerState" :ripple="false" dense hide-details class="mt-3 ml-2 mr-0" @change="onWorkerSelectionChanged"></v-checkbox>
			</div>
			<div class="d-flex flex-column" style="width: 100%;">
				<div class="d-flex align-top space-between">
					<v-card-subtitle class="px-0">
						<div class="font-weight-bold black--text">{{worker.user.name}}</div>
						<div class="caption">职位: {{worker.user.role}}</div>
						<div class="caption" v-if="mode" >邮箱: {{worker.user.email}}</div>
						<div class="caption">Clock In @ <span v-if="worker.clock_in_outs[0] && worker.clock_in_outs[0].clock_in">{{ worker.clock_in_outs[0].clock_in }}</span></div>
						<div class="caption">Clock Out @ <span v-if="worker.clock_in_outs[0] && worker.clock_in_outs[0].clock_out">{{ worker.clock_in_outs[0].clock_out }}</span></div>
					</v-card-subtitle>
				</div>
				<div class="text-center caption mb-3">
					<span class="info--text" v-if="worker.activities && worker.activities.length">
						已有{{ worker.activities.length }}条工时记录
					</span>
					<span v-else>
						无工时记录
					</span>
				</div>
				
				<v-card-actions class="px-0" v-if="!canselect">
					<template v-if="clocked_in == 0 && canEditTimesheet">
						<v-btn color="success" :block="!mode" elevation="0" small @click="onClockIn" style="min-width: 50%" ><v-icon small class="mr-1">mdi-clock-plus-outline</v-icon> Clock In</v-btn>
					</template>
					<template v-if="clocked_in == 1">
						<v-btn color="error" :block="!mode"  elevation="0" small style="min-width: 50%" @click="onClockOut"><v-icon small class="mr-1">mdi-clock-minus-outline</v-icon>Clock Out</v-btn>
					</template>
					<template v-if="clocked_in == 2">
						<div class="text-center success--text caption font-weight-bold" :style="!mode ? 'width: 100%' : 'min-width: 55%'"><v-icon small class="mr-1 success--text">mdi-check-all</v-icon> 打卡完成</div>
					</template>
					<v-btn color="info" elevation="0" small v-if="mode" style="min-width: 50%" @click="onEditTimesheet"><v-icon small class="mr-1">mdi-pencil</v-icon> 编辑工时</v-btn>
				</v-card-actions>
			</div>
			<div :class="!mode ? 'ml-4' : 'ml-0'">
				<v-btn v-if="mode && !canselect && canEditTimesheet" class="mt-2 mr-1" color="error" icon @click="onRemoveWorker"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
			</div>	
		</div>
	</v-card>
</template>

<script>

export default {
	name: 'workerCard',
	data: ()=> ({
		workerState: false
	}),
	props: {
		worker: {
			type: Object,
			required: true
		},
		clocked_in: {
			type: Number || String,
			default: 0  //0 - no clock in and clock out data, 1 - no clock out data, 2 - clock out and clock in data is filled. 
		},
		canEditTimesheet: {
			type: Boolean,
			required: true,
			default: true
		},
		mode: {
			type: Boolean,
			required: true,
			default: false
		},
		canselect: {
			type: Boolean,
			required: true
		},
		selectedWorkers: {
			type: Array,
			default: []
		}
	},
	watch: {

	},
	mounted(){
		this.workerState = this.isWorkerSelected(this.worker)
	},
	computed:{
		getWorkerState(){
			this.workerState = this.isWorkerSelected(this.worker)
			return this.isWorkerSelected(this.worker)
		}
	},
	methods: {
		onRemoveWorker() {
			this.$emit('on-remove-worker', this.worker)
		},
		onClockOut() {
			this.$emit('on-clock-out', this.worker)
		},
		onClockIn() {
			this.$emit('on-clock-in', this.worker)
		},
		onEditTimesheet(){
			this.$emit('on-edit-timesheet', this.worker)
		},
		onWorkerSelectionChanged(){
			this.$emit('on-worker-selection-change', this.worker)
		},
		isWorkerSelected(worker){
			//check if worker is checked state
			return this.selectedWorkers.findIndex((member)=>member.id === worker.id) === -1 ? false : true;
		},
	}
}
</script>
