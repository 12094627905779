var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dark":"","dense":"","elevation":"0","color":"primary"}},[_c('v-toolbar-title',[_vm._v("添加团队到今日项目")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v(" 关闭 ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-0 pt-0 pb-0"},[_c('div',{staticClass:"pa-3"},[_c('v-text-field',{staticClass:"mx-1 my-2",attrs:{"label":"搜索工人团队","outlined":"","dense":"","hide-details":"","clearable":"","clear-icon":"mdi-close","prepend-inner-icon":"mdi-magnify","placeholder":"输入工人团队名称"},on:{"click:clear":_vm.clearTeamSearch,"input":_vm.onSearchTeam},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-divider'),(_vm.loading)?[_c('div',{staticClass:"d-flex justify-center align-center flex-column mt-8 py-8"},[_c('v-progress-circular',{attrs:{"size":140,"width":2,"color":"primary","indeterminate":""}}),_c('div',{staticClass:"body-1 mt-4"},[_vm._v("载入数据中")])],1)]:[(_vm.search && _vm.search.length)?[(_vm.searchResult.length)?[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.searchResult,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"name","show-expand":"","footer-props":{
								'items-per-page-text': '每页显示',
								//'items-per-page-options': items
							}},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":function($event){return _vm.onTeamExpand($event)}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
							var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","small":"","elevation":"0"},on:{"click":function($event){return _vm.addTeamToProjectTimesheet(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" 添加团队 ")],1)]}},{key:"expanded-item",fn:function(ref){
							var headers = ref.headers;
							var item = ref.item;
return [_c('td',{staticClass:"px-0 grey lighten-4",attrs:{"colspan":headers.length}},[(item.workers && item.workers.length)?[_c('v-list',{staticClass:"pb-0",attrs:{"dense":"","subheader":""}},[_c('v-subheader',{staticClass:"text-center grey lighten-4"},[_vm._v(_vm._s(item.name)+"工人")]),_c('v-divider'),_vm._l((item.workers),function(worker){return [_c('v-list-item',{staticClass:"grey lighten-4"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(worker.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(worker.email))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(worker.role))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"success","small":"","elevation":"0"},on:{"click":function($event){return _vm.addWorkerToProjectTimesheet(worker)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" 添加 ")],1)],1)],1),_c('v-divider')]})],2)]:[(_vm.workerLoader)?[_c('div',{staticClass:"d-flex justify-center align-center flex-column mt-4 py-4"},[_c('v-progress-circular',{attrs:{"size":40,"width":2,"color":"primary","indeterminate":""}}),_c('div',{staticClass:"body-2 mt-2"},[_vm._v("获取团队工人数据中...")])],1)]:[_c('div',{staticClass:"pa-6 text-center body-2"},[_vm._v(" 没有工人在"),_c('strong',[_vm._v(_vm._s(item.name))]),_vm._v("的工作团队中 ")])]]],2)]}}],null,false,1904957624)})]:[_c('div',{staticClass:"body-2 text-center py-8"},[_vm._v("无法找到符合关键字"),_c('strong',[_vm._v(_vm._s(_vm.search))]),_vm._v("的结果")])]]:[(_vm.teams.length)?[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.teams,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"name","show-expand":"","footer-props":{
								'items-per-page-text': '每页显示',
								//'items-per-page-options': items
							}},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":function($event){return _vm.onTeamExpand($event)}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
							var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","small":"","elevation":"0"},on:{"click":function($event){return _vm.addTeamToProjectTimesheet(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" 添加团队 ")],1)]}},{key:"expanded-item",fn:function(ref){
							var headers = ref.headers;
							var item = ref.item;
return [_c('td',{staticClass:"px-0 grey lighten-4",attrs:{"colspan":headers.length}},[(item.workers && item.workers.length)?[_c('v-list',{staticClass:"pb-0",attrs:{"dense":"","subheader":""}},[_c('v-subheader',{staticClass:"text-center grey lighten-4"},[_vm._v(_vm._s(item.name)+"工人")]),_c('v-divider'),_vm._l((item.workers),function(worker){return [_c('v-list-item',{staticClass:"grey lighten-4"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(worker.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(worker.email))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(worker.role))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"success","small":"","elevation":"0"},on:{"click":function($event){return _vm.addWorkerToProjectTimesheet(worker, item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" 添加 ")],1)],1)],1),_c('v-divider')]})],2)]:[(_vm.workerLoader)?[_c('div',{staticClass:"d-flex justify-center align-center flex-column mt-4 py-4"},[_c('v-progress-circular',{attrs:{"size":40,"width":2,"color":"primary","indeterminate":""}}),_c('div',{staticClass:"body-2 mt-2"},[_vm._v("获取团队工人数据中...")])],1)]:[_c('div',{staticClass:"pa-6 text-center body-2"},[_vm._v(" 没有工人在"),_c('strong',[_vm._v(_vm._s(item.name))]),_vm._v("的工作团队中 ")])]]],2)]}}],null,false,1881874241)})]:[_c('div',{staticClass:"body-2 text-center py-8"},[_vm._v("没有工作组在这个项目中，请在项目设置中添加工作团队")])]]]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }