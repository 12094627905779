<template>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="290"
		>
		<v-card>
			<v-card-title class="headline">
				{{headline_text}}
			</v-card-title>
			<v-card-text>
				{{message}}
			</v-card-text>
			<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="grey"
				text
				@click="$emit('on-cancel')"
			>
				取消
			</v-btn>
			<v-btn
				:color="confirm_btn_color"
				text
				@click="$emit('on-confirm')"
			>
				确定
			</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>

export default {
	name: 'workerCard',
	data: ()=> ({
		dialog: false
	}),
	props: {
		type: {
			type: String,
			default: 'confirm'  // warn/ Delete
		},
		value: {
			type: Boolean,
			default: false
		},
		message: {
			type: String,
			default: ''
		}
	},
	watch:{
		value() {
			this.dialog = this.value
		}
	},
	computed: {
		headline_text() {
			if (this.type === 'confirm') {
				return '确认';
			} else if (this.type === 'warn') {
				return '警告';
			} else if (this.type === 'delete') {
				return '错误';
			}
		},
		confirm_btn_color() {
			if (this.type === 'confirm') {
				return 'success';
			} else if (this.type === 'warn') {
				return 'error';
			} else if (this.type === 'delete') {
				return 'error';
			}
		}
	}
}
</script>
