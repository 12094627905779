import Api from '@/services/Api';

export default {
	getWorkTeamList(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/work/team?${queryString}`);
	},
	createWorkTeam(body){
		return Api().post(`/work/team`, JSON.stringify(body));
	},
	getWorkTeam(teamId, params){
		if(params){
			let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			return Api().get(`/work/team/${teamId}?${queryString}`);
		} else {
			return Api().get(`/work/team/${teamId}`);
		}
	},
	deleteWorkTeam(teamId){
		return Api().delete(`/work/team/${teamId}`);
	},
	updateWorkTeam(teamId, body){
		return Api().put(`work/team/${teamId}`, JSON.stringify(body));
	}
}