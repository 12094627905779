import Api from '@/services/Api';

export default {
	listTimeSheetGroup(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/timesheet/group?${queryString}`);
	},
	createTimeSheetGroup(body){
		return Api().post(`/timesheet/group`, JSON.stringify(body));
	},
	getTimeSheetGroup(groupId){
		return Api().get(`/timesheet/group/${groupId}`);
	},
	getTimesheetRecordsByGroupId(groupId, workerId, workTeamId){
		return Api().get(`/timesheet/group/${groupId}/records?worker_id=${workerId}&work_team_id=${workTeamId}`);
	},
	updateTimesheetGroup(groupdId, body){ ///deprecated
		return Api().put(`/timesheet/group/${groupdId}`, JSON.stringify(body));
	},
	createTimesheetWorkTeam(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().post(`/timesheet/workteam?${queryString}`);
	},
	getTimesheetWorkTeam(teamId){
		return Api().get(`timesheet/workteam/${teamId}`);
	},
	deleteTimesheetWorkTeam(teamId){
		return Api().delete(`timesheet/workteam/${teamId}`);
	},
	getWorkerTimesheet(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/timesheet?${queryString}`);
	},
	updateTimesheetWorkTeam(teamId, body){
		return Api().put(`/timesheet/workteam/${teamId}`, JSON.stringify(body));
	},
	getDayLabourTimesheets(teamId){
		return Api().get(`/timesheet/workteam/${teamId}/day_labour`);
	},
	getPackageLabourTimesheets(teamId){
		return Api().get(`/timesheet/workteam/${teamId}/package`);
	},
	updateDayLabourTimesheets(teamId, body){
		return Api().post(`/timesheet/workteam/${teamId}/day_labour`, JSON.stringify(body));
	},
	updatePackageLabourTimesheets(teamId, body){
		return Api().post(`/timesheet/workteam/${teamId}/package`, JSON.stringify(body));
	},

	// version 2 timesheet api
	createDayEntry(body){
		return Api().post(`/timesheets_v2`, JSON.stringify(body))
	},
	listTimesheetDayEntry(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/timesheets_v2?${queryString}`)
	},
	viewTimesheetDayEntry(dayEntryId) {
		return Api().get(`/timesheets_v2/${dayEntryId}`)
	},

	getTimeSheetGroupV2(dayEntryId){
		return Api().get(`/timesheets_v2/${dayEntryId}/groups`)
	},
	viewTimeSheetGroup(groupId) {
		return Api()/get(`/timesheets_v2/groups/${groupId}`)
	},
	createGroup(dayEntryId, work_team_id) {
		const body = {
			"work_team_id": work_team_id
		}
		return Api().post(`/timesheets_v2/${dayEntryId}/groups`, JSON.stringify(body))
	},
	deleteGroup(groupId){
		return Api().delete(`/timesheets_v2/groups/${groupId}`)
	},
	createGroupMember(groupId, user_id){
		const body = {
			"user_id": user_id
		}
		return Api().post(`/timesheets_v2/groups/${groupId}/members`, JSON.stringify(body))
	},
	viewGroupMember(groupMemberId){
		return Api().get(`/timesheets_v2/group_members/${groupMemberId}`)
	},
	deleteGroupMember(groupMemberId){
		return Api().delete(`/timesheets_v2/group_members/${groupMemberId}`)
	},
	//clock in and clock out endpoints
	memberClockIn(groupMemberId) {
		return Api().post(`/timesheets_v2/group_members/${groupMemberId}/clock_in`)
	},
	memberClockOut(groupMemberId) {
		return Api().post(`/timesheets_v2/group_members/${groupMemberId}/clock_out`)
	},
	createActivity(groupMemberId, body){
		return Api().post(`timesheets_v2/group_members/${groupMemberId}/activities`, JSON.stringify(body))
	},
	deleteActivity(groupMemberId, activityId){
		return Api().delete(`/timesheets_v2/group_members/${groupMemberId}/activities/${activityId}`)
	},
	editActivity(groupMemberId, activityId, body){
		return Api().patch(`/timesheets_v2/group_members/${groupMemberId}/activities/${activityId}`,  JSON.stringify(body))
	},
	editDayEntry(dayEntryId,body){
		return Api().patch(`/timesheets_v2/${dayEntryId}`, JSON.stringify(body))
	},
}