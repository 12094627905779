<template>
	<v-card>
		<v-toolbar
			dark
		  	dense
		  	elevation="0"
          	color="primary"
        >
          <v-toolbar-title>添加工人到项目</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
            @click="$emit('on-close')"
            >
              关闭
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
		<v-divider></v-divider>
		<v-card-text class="px-0 pt-0 pb-8">
			<!--- 工人搜索框 --->
			<div class="pa-2">
				<v-text-field
					label="搜索项目工人"
					v-model="search"
					outlined
					dense
					hide-details
					clearable
					clear-icon="mdi-close"
					prepend-inner-icon="mdi-magnify"
					@click:clear="clearWorkerSearch"
					@input="onSearchWorker"
					placeholder="输入项目工人名称"
					class="mx-1 my-2"
				></v-text-field>
				<v-spacer></v-spacer>
			</div>
			<v-divider></v-divider>
			<!--- loading overlay ---->
			<template v-if="loading">
				<div class="d-flex justify-center align-center flex-column mt-8 py-8">
					<v-progress-circular
					:size="140"
					:width="2"
					color="primary"
					indeterminate
					></v-progress-circular>
					<div class="body-1 mt-4">载入数据中</div>
				</div>
			</template>
			<template v-else>
				<template v-if="search && search.length">
					<template v-if="searchResult.length">
						<v-data-table
							:headers="headers"
							:items="searchResult"
							item-key="name"
							:footer-props="{
								'items-per-page-text': '每页显示',
								//'items-per-page-options': items
							}"
						>
								<template v-slot:item.actions="{item}">
									<v-btn color="success" small elevation="0" @click="addWorkerToProjectTimesheet(item)">
										<v-icon small left>mdi-plus</v-icon>
										添加
									</v-btn>
								</template>
						</v-data-table>
					</template>
					<template v-else>
						<div class="body-2 text-center py-8">无法找到符合关键字<strong>{{ search }}</strong>的结果</div>
					</template>
				</template>
				<template v-else>
					<template v-if="workers.length">
						<v-data-table
							:headers="headers"
							:items="workers"
							item-key="name"
							:footer-props="{
								'items-per-page-text': '每页显示',
								//'items-per-page-options': items
							}"
						>
								<template v-slot:item.actions="{item}">
									<v-btn color="success" small elevation="0" @click="addWorkerToProjectTimesheet(item)">
										<v-icon small left>mdi-plus</v-icon>
										添加
									</v-btn>
								</template>
						</v-data-table>
					</template>
					<template v-else>
						<div class="body-2 text-center py-8">没有工人在这个项目中，请在项目设置中添加工人</div>
					</template>
				</template>
			</template>
		</v-card-text>
	</v-card>
</template>
<script>

import ProjectServices from '../../services/Project';
import { mapState } from 'vuex';

export default {
	name: 'AddWorkerToTimesheetDialog',
	data: ()=>({
		search: '',
		loading: true,
		headers: [
			{ text: 'id', value: 'id'},
			{ text: '用户名', value: 'name'},
			{ text: '邮箱', value: 'email'},
			{ text: '用户角色', value: 'role'},
	        { text: '功能', value: 'actions', sortable: false, width: 200, align: 'end',  }
		],
		workers: [],
		searchResult: [],
	}),
	props: {
		projectId: {
			type: String | Number,
			required: true,
		}
	},
	created(){
		this.getProjectAssignedWorkers();
	},
	computed: {
		...mapState('user', ['user'],),
	},
	methods: {
		async getProjectAssignedWorkers(){
			try {
				 let response = await ProjectServices.listProjectUsers(this.projectId);
				 if(response.data.statusCode === 200){
					response.data.data.forEach(user=>{
						this.workers.push(user)
						this.loading = false;
					})
				}
			} catch (error){
				this.popToast(error, 'error');
			}
		},
		onSearchWorker(){
			const searchResult = this.workers.filter((worker)=>{
				return worker.name.includes(this.search);
			})
			this.searchResult = searchResult
		},
		clearWorkerSearch(){
			this.search = ''
			this.searchResult = [];
		},
		addWorkerToProjectTimesheet(worker){
			//call endpoint to add worker to project timesheet. need pop totast
			this.$emit('on-worker-add', worker);
		},
		popToast(message, type){
			const toast = {
				message: message,
				color: type,
			}
			this.$emit('on-pop-toast', toast);
		},
	}
}
</script>
