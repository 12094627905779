import Api from '@/services/Api';

export default {
	getProjectList(page, itemsPerPage, status){
		return Api().get(`/project?page=${page}&per_page=${itemsPerPage}&status=${status}`);
	},
	getAssignedProjectList(page, itemsPerPage, status){
		return Api().get(`/project/assigned?page=${page}&per_page=${itemsPerPage}&status=${status}`);
	},
	createProject(body){
		return Api().post(`/project`, JSON.stringify(body));
	},
	getProjectInfo(projectID) {
		return Api().get(`/project/${projectID}`);
	},
	removeProjectSiteManager(projectId, managerId){
		return Api().delete(`/project/${projectId}/site_manager?user_id=${managerId}`);
	},
	addProjectSiteManager(projectId, managerId){
		return Api().post(`/project/${projectId}/site_manager?user_id=${managerId}`);
	},
	updateProjectInfo(projectId, body){
		return Api().put(`/project/${projectId}`, JSON.stringify(body));
	},
	// This endpoint is only used for project work team break down report. 
	listProjectWorkTeams(projectId){
		return Api().get(`/project/${projectId}/workteam`);
	},
	listProjectWorkTeamWorkers(workerId){
		return Api().get(`/project/workteam/${workerId}`)
	},
	listAvailableWorkTypes(projectId) {
		return Api().get(`/project/${projectId}/work_types`)
	},
	//New Proejct endpoint added 17/06/2024
	listProjectUsers(projectId) {
		return Api().get(`/project/${projectId}/users`);
	},
	addProjectUser(projectId, userId) {
		const payload = {
			"user_id": userId
		}
		return Api().post(`/project/${projectId}/user`, JSON.stringify(payload))
	},
	removeProjectUser(projectId, userId) {
		return Api().delete(`/project/${projectId}/user?user_id=${userId}`)
	},
	listWorkTeamsInProject(projectId) {
		return Api().get(`/project/${projectId}/work_teams`);
	},
	addWorkTeamToProject(projectId, workTeamId) {
		const payload = {
			"work_team_id": workTeamId
		}
		return Api().post(`/project/${projectId}/work_team`, JSON.stringify(payload));
	},
	removeWorkTeamFromProject(projectId, workTeamId) {
		return Api().delete(`/project/${projectId}/work_team?work_team_id=${workTeamId}`);
	}

}